import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";

import {
  Wrapper,
  Container,
  Image,
  WelcomeContainer,
  Welcome,
  WelcomeMessage,
  DownButtonContainer,
  DownButton,
  DownArrow
} from "./styles";
import { SlideRightAnimContainer } from "../../styles/animations";

interface IProps {
  scrollFunc(): void;
  image: {
    url: string;
    alt: string;
  };
  position?: number;
  welcome_heading?: string;
  welcome_message?: string;
}

const Banner: FunctionComponent<IProps> = props => {
  const { formatMessage } = useIntl();
  const DownButtonRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<number>(0);
  const [DownButtonOpacity, SetButtonOpacity] = useState<number>(1);
  const [unlockAnimation, setAnimationUnlocked] = useState<boolean>(false);
  const [animationDone, setAnimationDone] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }
    const delay = setTimeout(() => {
      setAnimationUnlocked(true);
    }, 1000);
    const timer = setTimeout(() => {
      setAnimationDone(true);
    }, 3000);

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
      clearTimeout(delay);
      clearTimeout(timer);
    };
  }, []);

  const handleScroll = (_e: any) => {
    setPosition(window?.scrollY);
    handleButtonFade(window?.scrollY);
  };

  const handleButtonFade = (pos: number) => {
    if (DownButtonRef.current) {
      // Fade button when approaching the last bit of the banner
      const viewSize = window.innerHeight;
      const headerSize = 140;
      const bannerSize = window.innerHeight * 1.5;
      const areaSize = headerSize + bannerSize;

      const fadeThreshold = viewSize + viewSize * 0.2;
      const diff = areaSize - pos;

      if (diff < viewSize) {
        SetButtonOpacity(0);
      } else if (diff < fadeThreshold) {
        const curr = diff - viewSize;
        const max = fadeThreshold - viewSize;
        SetButtonOpacity(curr / max);
      } else {
        SetButtonOpacity(1);
      }
    } else {
      SetButtonOpacity(0);
    }
  };

  const {
    image: { url: image, alt },
    scrollFunc
  } = props;
  const height =
    typeof window !== "undefined" ? window.innerHeight / 2 + 144 : 574;

  return (
    <Wrapper position={position < height ? "fixed" : "fixed"}>
      <Container position={position < height ? "fixed" : "fixed"}>
        <SlideRightAnimContainer duration={2}>
          <Image src={image} alt={alt} />
        </SlideRightAnimContainer>
        <WelcomeContainer
          faster={animationDone}
          middle={unlockAnimation}
          full={
            unlockAnimation && animationDone && position > height / 2
              ? true
              : false
          }
        >
          <Welcome>
            {props.welcome_heading || formatMessage({ id: "welcome" })}
          </Welcome>
          <WelcomeMessage>
            {props.welcome_message || formatMessage({ id: "welcome-message" })}
          </WelcomeMessage>
        </WelcomeContainer>
      </Container>

      <DownButtonContainer onClick={scrollFunc}>
        <motion.div
          animate={{
            y: [-35, 0, -35]
          }}
          transition={{
            loop: Infinity,
            duration: 2,
            ease: "easeInOut",
            repeatDelay: 2
          }}
        >
          <DownButton opacity={DownButtonOpacity} ref={DownButtonRef}>
            <DownArrow />
          </DownButton>
        </motion.div>
      </DownButtonContainer>
    </Wrapper>
  );
};

export default Banner;
