import React, { RefObject, FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import ReactVisibilitySensor from "react-visibility-sensor";

// @ts-ignore
import handleViewport from "react-in-viewport";

import {
  HandleViewportProps,
  FadeSlideUpOnActiveContainer
} from "../../styles/animations";
import Button from "../common/Button";

import {
  SolutionsContainer,
  Wrapper,
  SolutionsIntro,
  SolutionsIntroTitle,
  SolutionsIntroContent,
  SolutionsAdaptative,
  PreviewsContainer
} from "./styles";

interface IProps extends HandleViewportProps {
  reference: RefObject<HTMLDivElement>;
  content: {
    data: {
      title: string;
      intro: {
        html: string;
      };
      background?: {
        url: string;
      };
    };
  };
  previews: {
    nodes: Array<{
      uid: string;
      data: {
        order: number;
        text: Array<{ text: string; type: string }>;
        title: Array<{ text: string; type: string }>;
        image: { url: string; alt: string };
      };
    }>;
  };
  skipAnimation?: boolean;
}

const Solutions: FunctionComponent<IProps> = props => {
  const { locale, formatMessage } = useIntl();
  const {
    reference,
    content: {
      data: {
        title,
        intro: { html: content }
      }
    },
    previews: { nodes: previews }
  } = props;

  const [animatorActivatedArray, setAnimatorArray] = useState(() =>
    new Array(previews.length).fill(false)
  );

  const { enterCount, forwardedRef, skipAnimation } = props;

  return (
    <SolutionsContainer id="solutions" ref={forwardedRef}>
      <Wrapper ref={reference}>
        <SolutionsIntro>
          <SolutionsIntroTitle>{title}</SolutionsIntroTitle>
          <SolutionsIntroContent
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </SolutionsIntro>
        {(enterCount > 0 || skipAnimation) && (
          <PreviewsContainer>
            {previews.map(
              (
                {
                  data: {
                    image: { url: image, alt },
                    text,
                    title: titleStr,
                    order
                  },
                  uid
                },
                index
              ) => {
                const message = text.map(par => par.text).join(" ");
                return (
                  <ReactVisibilitySensor
                    partialVisibility
                    offset={{ bottom: 100 }}
                    key={`solutions-preview-${order % 2 === 0 ? 2 : 1}-${index}`}
                    onChange={isVisible => {
                      if (isVisible) {
                        const temp = [...animatorActivatedArray];
                        temp[index] = true;
                        setAnimatorArray(temp);
                      }
                    }}
                  >
                    <FadeSlideUpOnActiveContainer
                      active={animatorActivatedArray[index]}
                      duration={1}
                    >
                      <div
                        className={`solutions-preview solutions-preview-${
                          order % 2 === 0 ? 2 : 1
                        }`}
                      >
                        <div
                          className={`solutions-preview-background-container solutions-preview-background-container-${
                            order % 2 === 0 ? 2 : 1
                          }`}
                        >
                          <div
                            className={`solutions-rectangle solutions-rectangle-${
                              order % 2 === 0 ? 2 : 1
                            }`}
                          />
                          <img
                            alt={alt}
                            src={image}
                            className={`solutions-image solutions-image-${
                              order % 2 === 0 ? 2 : 1
                            }`}
                          />
                        </div>
                        <SolutionsAdaptative smaller={index === 2}>
                          <div
                            className={`solutions-info solutions-info-${
                              order % 2 === 0 ? 2 : 1
                            }`}
                          >
                            <div
                              className={`solutions-info-title solutions-info-title-${
                                order % 2 === 0 ? 2 : 1
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: titleStr[0]?.text
                              }}
                            />
                            {
                              <div
                                className={`solutions-info-text solutions-info-text-${
                                  order % 2 === 0 ? 2 : 1
                                }`}
                                dangerouslySetInnerHTML={{ __html: message }}
                              />
                            }
                            <Button to={`/${locale}/solutions/${uid}`}>
                              {formatMessage({ id: "learn-more" })}
                            </Button>
                          </div>
                        </SolutionsAdaptative>
                      </div>
                    </FadeSlideUpOnActiveContainer>
                  </ReactVisibilitySensor>
                );
              }
            )}
          </PreviewsContainer>
        )}
      </Wrapper>
    </SolutionsContainer>
  );
};

export default handleViewport(Solutions, { threshold: 0.5 });
