import styled from "@emotion/styled";

export const Wrapper = styled.div<{ position: string }>`
  height: 150vh;
  width: 100%;
  background-color: none;
  ${props =>
    props.position === "relative"
      ? `
    display: flex;
    align-items: flex-end;
  `
      : ""}
`;

export const Container = styled.div<{ position: string }>`
  display: flex;
  position: ${props => props.position};
  height: calc(100vh - 144px);
  overflow: hidden;
  width: 100%;
  z-index: -15;
`;

export const Image = styled.img`
  width: 70%;
  height: calc(100vh - 144px);
  position: relative;
  object-fit: cover;
`;

interface WelcomeContainerProps {
  faster?: boolean;
  middle?: boolean;
  full?: boolean;
}
export const WelcomeContainer = styled.div<WelcomeContainerProps>`
  width: 95%;
  height: 440px;
  position: absolute;
  font-family: Roboto;
  font-weight: 300;
  top: 13%;
  opacity: 0.9;
  background: ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};

  right: -100%;
  transition: right 2s cubic-bezier(0.7, 0, 0.3, 1);

  ${props =>
    props.faster ? `transition: right 1s cubic-bezier(0.7, 0, 0.3, 1);` : ``}
  ${props => (props.middle ? `right: -40%;` : ``)}
  ${props => (props.faster && props.full ? `right: 0%;` : ``)}
`;

export const Welcome = styled.div`
  width: 448px;
  height: 130px;
  color: white;
  font-family: Roboto;
  font-weight: 300;
  font-size: 50px;
  line-height: 1.3;
  margin-left: 176px;
  margin-top: 155px;
`;

export const WelcomeMessage = styled.div`
  width: 30%;
  position: absolute;
  right: 10%;
  top: 36%;
  font-family: Roboto;
  font-weight: 300;
  font-size: 21px;
  line-height: 1.43;
  color: white;
`;

export const DownButtonContainer = styled.div`
  position: fixed;
  padding-top: 30px;
  width: 100%;
  height: 82px;
  bottom: 15px;
  display: flex;
  justify-content: center;
`;

export const DownButton = styled.div<{ opacity: any }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  opacity: ${props => props.opacity};
  ${props => props.opacity === 0 && `display: none;`};
`;

export const DownArrow = styled.div`
  width: 13px;
  height: 13px;
  ${(props: { theme?: any }) => `
  border-bottom: 1px solid ${props.theme.colors.decunifyOrange};
  border-right: 1px solid ${props.theme.colors.decunifyOrange};
  `}
  transform: rotate(45deg);
`;

const styles = {
  Wrapper,
  Container,
  Image,
  WelcomeContainer,
  Welcome,
  WelcomeMessage,
  DownButtonContainer,
  DownButton,
  DownArrow
};

export default styles;
