import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";

// @ts-ignore
import { merge, fadeIn, slideInUp, slideInLeft } from "react-animations";

export interface HandleViewportProps {
  enterCount: number;
  leaveCount: number;
  inViewport: boolean;
  forwardedRef: any;
}

// Custom animation.
const fadeInSlideUp = merge(fadeIn, slideInUp);
const fadeInSlideLeft = merge(fadeIn, slideInLeft);

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeSlideUpAnimation = keyframes`${fadeInSlideUp}`;
const fadeSlideLeftAnimation = keyframes`${fadeInSlideLeft}`;

interface AnimationProps {
  duration?: number;
  delay?: number;
}

export const FadeInAnimationContainer = styled.div<AnimationProps>`
  animation: ${props => `${props.duration}s` || "1s"} ${fadeInAnimation};
`;
export const FadeSlideAnimationContainer = styled.div<AnimationProps>`
  animation: ${props => `${props.duration}s` || "1s"} ${fadeSlideUpAnimation};
`;
export const SlideRightAnimContainer = styled.div<AnimationProps>`
  /* Up and Down are rversed compared to Left and Right */
  animation: ${props => `${props.duration}s` || "3s"} ${fadeSlideLeftAnimation};
  transform: translateZ(0);
`;

interface OnClickProps extends AnimationProps {
  active: boolean;
}
export const FadeSlideUpOnActiveContainer = styled.div<OnClickProps>`
  ${props =>
    props.active
      ? css`
          animation: ${props.duration || 1}s ${fadeSlideUpAnimation};
        `
      : css`
          opacity: 0;
        `}
`;
