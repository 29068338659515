import React, { RefObject, FunctionComponent } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useIntl } from "react-intl";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

// @ts-ignore
import handleViewport from "react-in-viewport";
import { HandleViewportProps } from "../../styles/animations";

import Button from "../common/Button";
import { Container as RContainer, Row, Col } from "react-grid-system";

import { Container, Wrapper, Cblog, Post } from "./styles";

interface BlogProps extends HandleViewportProps {
  tags?: any;
  seeNext?: boolean;
  reference?: RefObject<HTMLDivElement>;
  posts: {
    nodes: Array<{
      uid: string;
      data: {
        preview_image: { url: string; alt: string };
        title: string;
        intro: string;
        date: string;
      };
    }>;
  };
}

const Blog: FunctionComponent<BlogProps> = (props) => {
  const { locale, formatMessage } = useIntl();
  const formatDate = (date: string) =>
    new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  const querydata: {
    arrow: {
      publicURL: string;
    };
    trasitionBlog: {
      publicURL: string;
    };
  } = useStaticQuery(graphql`
    query {
      arrow: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "icons/right-thin-chevron.svg" }
      ) {
        publicURL
      }
      trasitionBlog: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/blog.png" }
      ) {
        publicURL
      }
    }
  `);

  const {
    posts: { nodes: posts },
    forwardedRef,
  } = props;

  return (
    <RContainer ref={forwardedRef}>
      <Container ref={props?.reference} id="blog">
        <Wrapper>
          <Cblog>
            {props.seeNext ? (
              <h3 className="follow-next">
                {formatMessage({ id: "see-also" }) + ":"}
              </h3>
            ) : (
              <AniLink
                cover
                bg={`url(${querydata.trasitionBlog.publicURL}) center/25% no-repeat #f38f1d`}
                duration={1}
                direction="top"
                to={`/${locale}/blog`}
                style={{ textDecorationColor: "white" }}
              >
                <h1 className="blog-title upper-case">
                  {formatMessage({ id: "blog" })}
                </h1>
              </AniLink>
            )}
          </Cblog>
          <Row style={{ justifyContent: "space-around" }}>
            {posts.map((post, index) => (
              <Col
                xs={12}
                xl={4}
                key={`post-${index}`}
                style={{ marginBottom: "20px", minWidth: "290px" }}
              >
                <Post>
                  <div
                    className="blog-post-image"
                    key={`blog-image-key-${index}`}
                  >
                    {post.data.preview_image && post.data.preview_image.url ? (
                      <img
                        src={post.data.preview_image.url}
                        alt={post.data.preview_image.alt}
                        className="post-image"
                      />
                    ) : null}
                    <div className={`rec${index}`} />
                  </div>
                  <Link
                    className="blog-title-link"
                    to={`/${locale}/blog/posts/${post.uid}`}
                  >
                    <div className="blog-post-title">
                      <h1 className="post-title">{post.data.title}</h1>
                    </div>
                    <div className="line-post" />
                    <div className="blog-post-intro">
                      <h2 className="post-intro">{post.data.intro}</h2>
                    </div>
                  </Link>
                  <div className="know-more-row">
                    <div className="know-more-row-col">
                      <div className="post-date">
                        {formatDate(post.data.date)}
                      </div>
                    </div>
                    <div className="know-more-row-col">
                      <AniLink
                        cover
                        bg={`url(${querydata.trasitionBlog.publicURL}) center/25% no-repeat #f38f1d`}
                        duration={1}
                        direction="top"
                        className="row"
                        style={{ textDecorationColor: "#f38f1d" }}
                        to={`/${locale}/blog/posts/${post.uid}`}
                      >
                        <div className="post-link upper-case">
                          {formatMessage({ id: "read-more" })}
                        </div>
                        <img
                          src={querydata.arrow.publicURL}
                          className="read-more-arrow"
                          alt="read more arrow button"
                        />
                      </AniLink>
                    </div>
                  </div>
                </Post>
              </Col>
            ))}
          </Row>
          <Cblog>
            <AniLink
              cover
              bg={`url(${querydata.trasitionBlog.publicURL}) center/25% no-repeat #f38f1d`}
              duration={1}
              direction="top"
              to={`/${locale}/blog`}
              style={{ textDecorationColor: "white" }}
            >
              <Button style={{ marginTop: "75px" }} onClick={() => {}}>
                {props.seeNext
                  ? formatMessage({ id: "go-back" })
                  : formatMessage({ id: "load-more-posts" })}
              </Button>
            </AniLink>
          </Cblog>
        </Wrapper>
      </Container>
    </RContainer>
  );
};

export default handleViewport(Blog);
