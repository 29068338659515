import styled from "@emotion/styled";

export const CaseStudiesTitle = styled.h2<{ center?: boolean }>`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  color: #f38f1d;
  margin-top: 155px;
  text-transform: uppercase;
  text-align: ${props => props.center && "center"};
  margin-bottom: ${props => (props.center ? "38px" : "10px")};
`;

export const CaseStudiesSubtitle = styled.h3<{ center?: boolean }>`
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
  text-align: ${props => props.center && "center"};
  margin-bottom: ${props => (props.center ? "100px" : 0)};
`;

export const CaseStudyWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  min-height: 640px;
  padding-top: 155px;
  padding-bottom: 35px;

  @media only screen and (max-width: 786px) {
    min-height: 450px;
    height: 100%;
  }
`;

export const CaseStudyDimmer = styled.div`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const CaseStudyImage = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.65) blur(1px);
`;

export const CaseStudyName = styled.h4`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 10px;
`;

export const CaseStudyDivider = styled.hr`
  width: 100%;
  height: 1px;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: #f38f1d;
`;

export const CaseStudyDescription = styled.h5`
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 30px;
  cursor: pointer;

  @media only screen and (max-width: 786px) {
    margin-bottom: 50px;
    font-size: 18px;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 80%;
  margin-left: 10%;
  position: relative;
  bottom: 150px;

  @media only screen and (max-width: 786px) {
    bottom: 50px;
  }
`;

const styles = {
  CaseStudiesTitle,
  CaseStudiesSubtitle,
  CaseStudyWrapper,
  CaseStudyDimmer,
  CaseStudyImage,
  CaseStudyName,
  CaseStudyDivider,
  CaseStudyDescription,
  ButtonsRow,
};

export default styles;
