import React, { RefObject, FunctionComponent, useState } from "react";
// @ts-ignore
import { useIntl } from "react-intl";
import { Row, Col, Visible } from "react-grid-system";
import SwipeableViews from "react-swipeable-views";
import { Link } from 'gatsby';
import {
  faChevronLeft,
  faChevronRight,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../common/Button";

import {
  CaseStudiesTitle,
  CaseStudiesSubtitle,
  CaseStudyWrapper,
  CaseStudyDimmer,
  CaseStudyImage,
  CaseStudyName,
  CaseStudyDivider,
  CaseStudyDescription,
  ButtonsRow,
} from "./styles";

interface CaseStudy {
  reference?: RefObject<HTMLDivElement>;
  caseStudy: {
    nodes: Array<{
      uid: string;
      data: {
        client: string;
        case_description: string;
        cover: { url: string; alt: string };
      };
    }>;
  };
}

const CaseStudy: FunctionComponent<CaseStudy> = ({ caseStudy, reference }) => {
  const { locale, formatMessage } = useIntl();
  const [currCaseStudy, setCurrCaseStudy] = useState<number>(0);

  const nextCaseStudy = () => {
    if (currCaseStudy < caseStudy.nodes.length - 1) {
      setCurrCaseStudy(currCaseStudy + 1);
    } else if (currCaseStudy === caseStudy.nodes.length - 1) {
      setCurrCaseStudy(0);
    }
  };

  const previousCaseStudy = () => {
    if (currCaseStudy > 0) {
      setCurrCaseStudy(currCaseStudy - 1);
    } else if (currCaseStudy === 0) {
      setCurrCaseStudy(caseStudy.nodes.length - 1);
    }
  };

  return (
    <div ref={reference}>
      <div style={{ position: "relative" }}>
        <div id="case-studies" style={{ position: "absolute", top: "-9rem" }}/>
      </div>
      <Row>
        <Col xs={12} lg={5}>
          <Visible xs sm md>
            <CaseStudiesTitle center>
              {formatMessage({ id: "case-studies" })}
            </CaseStudiesTitle>
            <CaseStudiesSubtitle center>
              {formatMessage({ id: "case-studies-success" })}
            </CaseStudiesSubtitle>
          </Visible>
          <Visible lg xl>
            <CaseStudiesTitle>
              {formatMessage({ id: "case-studies" })}
            </CaseStudiesTitle>
            <CaseStudiesSubtitle>
              {formatMessage({ id: "case-studies-success" })}
            </CaseStudiesSubtitle>
          </Visible>
        </Col>
        <Col xs={12} lg={7}>
          <SwipeableViews index={currCaseStudy}>
            {caseStudy.nodes.map((caseEntry, cIndex) => (
              <CaseStudyWrapper key={cIndex}>
                <CaseStudyDimmer />
                <CaseStudyImage
                  alt={caseEntry.data.cover.alt}
                  src={caseEntry.data.cover.url}
                />
                <div
                  style={{
                    zIndex: 2,
                    minWidth: "61.7%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                    to={`/${locale}/case-studies/${caseEntry.uid}`}
                  >
                    <CaseStudyName>{caseEntry.data.client}</CaseStudyName>
                    <CaseStudyDivider />
                    <CaseStudyDescription>
                      {caseEntry.data.case_description}
                    </CaseStudyDescription>
                  </Link>
                  <ButtonsRow>
                    <div className="Arrow-Button" onClick={previousCaseStudy}>
                      <FontAwesomeIcon
                        size="lg"
                        color="#f38f1d"
                        icon={faChevronLeft}
                      />
                    </div>
                    <Button
                      to={`/${locale}/case-studies/${caseEntry.uid}`}
                      transparent
                    >
                      {formatMessage({ id: "see-more" })}
                    </Button>
                    <div className="Arrow-Button" onClick={nextCaseStudy}>
                      <FontAwesomeIcon
                        size="lg"
                        color="#f38f1d"
                        icon={faChevronRight}
                      />
                    </div>
                  </ButtonsRow>
                </div>
              </CaseStudyWrapper>
            ))}
          </SwipeableViews>
        </Col>
      </Row>
    </div>
  );
};

export default CaseStudy;
