import styled from "@emotion/styled";

export const SolutionsContainer = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
`;

export const SolutionsIntro = styled.div`
  padding: 95px 36px 0;
  margin-bottom: 75px;
  width: 100%;
`;

export const SolutionsIntroTitle = styled.div`
  font-family: Roboto;
  font-size: 42px;
  line-height: 1.19;
  color: ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};
  text-align: center;
  margin-bottom: 32px;
`;

export const SolutionsIntroContent = styled.div`
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.44;
  color: #000000;

  p {
    margin-bottom: 0px;
    text-align: center;
  }

  /* md-breakpoint */
  @media (min-width: 786px) {
    font-size: 21px;
  }
`;

export const SolutionsAdaptative = styled.div<{ smaller?: boolean }>`
  height: auto;
  @media (min-width: 786px) {
    height: 0;
    margin-bottom: ${props => (props.smaller ? "80px" : "80px")};
  }
`;

export const PreviewsContainer = styled.div`
  width: 100%;
  /* md-breakpoint */
  @media (min-width: 786px) {
    max-width: 876px;
  }

  .solutions-preview {
    margin-bottom: 120px;

    @media (max-width: 786px) {
      margin-bottom: 0px;
    }

    .solutions-preview-background-container {
      position: relative;
      max-height: 600px;
      /* md-breakpoint */
      @media (min-width: 786px) {
        max-width: 486px;
        &-2 {
          margin-left: auto;
          margin-right: 0;
        }
        &-3 {
          max-height: 700px;
        }
        &-4 {
          margin-left: auto;
          margin-right: 0;
          max-width: 392px;
        }
      }
      .solutions-rectangle {
        position: absolute;
        height: 67%;
        width: 20%;
        background-color: ${(props: { theme?: any }) =>
          props.theme.colors.decunifyOrange};
        &-1 {
          top: 42%;
        }
        &-2 {
          top: -15%;
          right: 0;
        }
        &-3 {
          top: -26%;
          @media (min-width: 786px) {
            top: -35%;
          }
        }
        &-4 {
          top: 47%;
          right: 0;
        }
      }
      .solutions-image {
        position: relative;
        object-fit: cover;
        height: 392px;
        /* md-breakpoint */
        @media (min-width: 786px) {
          min-height: 392px;
        }

        &-1 {
          padding-left: 36px;
        }
        &-2 {
          padding-right: 36px;
        }
        &-3 {
          padding-left: 36px;
          width: 450px;
          height: 392px;
        }
        &-4 {
          padding-right: 36px;
        }
      }
    }

    .solutions-info {
      background-color: white;
      position: relative;
      min-height: 235px;
      top: -115px;
      z-index: 2;

      width: 80%;
      max-width: 430px;
      margin: 0px auto;
      padding: 35px 25px;

      /* md-breakpoint */
      @media (min-width: 786px) {
        max-width: 520px;
        &-1 {
          margin-right: 0;
          top: -235px;
        }
        &-2 {
          margin-left: 0;
          top: -250px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        &-3 {
          margin-right: 0;
          top: -515px;
        }
        &-4 {
          margin-left: 0;
          top: -400px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      .solutions-info-title {
        font-family: Roboto;
        font-size: 36px;
        p {
          margin-bottom: 25px;
          font-weight: 300;
          line-height: normal;
        }
        &-1 {
          text-align: left;
        }
        &-2 {
          text-align: right;
        }
        &-3 {
          text-align: left;
        }
        &-4 {
          text-align: right;
        }
      }
      .solutions-info-text {
        font-family: Roboto;
        font-size: 18px;
        max-width: 392px;
        margin-bottom: 40px;
        p {
          margin-bottom: 0px;
        }
        &-1 {
          text-align: left;
        }
        &-2 {
          text-align: right;
        }
        &-3 {
          text-align: left;
        }
        &-4 {
          text-align: right;
        }
      }
      .solutions-info-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid
          ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};
        height: 45px;
        width: 176px;

        &-2 {
          float: right;
        }
        &-4 {
          float: right;
        }

        span {
          letter-spacing: 1.5px;
          text-decoration: none;
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          color: black;
          font-style: normal;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`;
