import React, { useState, RefObject, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import ReactVisibilitySensor from "react-visibility-sensor";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

// @ts-ignore
import handleViewport from "react-in-viewport";

import Button from "../common/Button";
import {
  HandleViewportProps,
  FadeInAnimationContainer,
  FadeSlideUpOnActiveContainer
} from "../../styles/animations";

import styles from "./styles";

interface IProps extends HandleViewportProps {
  goTo: string;
  reference?: RefObject<HTMLDivElement>;
  services: {
    nodes: Array<{
      uid: string;
      data: {
        title: string;
        sub_title: string;
        intro: string;
        active_link: number;
        link_label: { text: string };
        image: { url: string; alt: string };
      };
    }>;
  };
}

const Services: FunctionComponent<IProps> = props => {
  const { locale } = useIntl();
  const [serviceIndex, setIndex] = useState<number>(1);
  const [animationUnlocked, setAnimationLock] = useState<boolean>(false);

  const {
    goTo,
    services: { nodes: services },
    reference
  } = props;

  const {
    Container,
    Wrapper,
    ServicesContainer,
    ServiceLosange,
    ServiceImages,
    ServiceInfo,
    ServiceImage,
    ServiceBorder
  } = styles;

  const { enterCount, forwardedRef } = props;
  return (
    <Container id="services" ref={forwardedRef}>
      <Wrapper ref={reference}>
        <ServicesContainer>
          <ServiceImages>
            {enterCount > 0 &&
              services.map((service, index) => (
                <ServiceLosange
                  onClick={() => setIndex(index)}
                  key={`service-losange-${index + 1}`}
                >
                  <FadeInAnimationContainer duration={1 + 1 * index}>
                    <ServiceImage
                      className={serviceIndex === index ? "active" : ""}
                      src={service.data.image.url}
                      key={`service-losange-image-${index + 1}`}
                      alt={service.data.title}
                    />
                  </FadeInAnimationContainer>
                </ServiceLosange>
              ))}
            <ServiceBorder />
          </ServiceImages>
          <ServiceInfo>
            {enterCount > 0 && (
              <>
                <ReactVisibilitySensor
                  partialVisibility
                  offset={{ bottom: 100 }}
                  onChange={isVisible => {
                    if (!animationUnlocked && isVisible) {
                      setAnimationLock(true);
                    }
                  }}
                >
                  <FadeSlideUpOnActiveContainer
                    active={animationUnlocked}
                    duration={2}
                  >
                    <h1 className="service-title">
                      {services[serviceIndex].data.title}
                    </h1>
                    <h2 className="service-subtitle">
                      {services[serviceIndex].data.sub_title}
                    </h2>
                  </FadeSlideUpOnActiveContainer>
                </ReactVisibilitySensor>
                <FadeSlideUpOnActiveContainer
                  active={animationUnlocked}
                  duration={3}
                >
                  <div className="service-info-text">
                    {services[serviceIndex].data.intro}
                  </div>
                </FadeSlideUpOnActiveContainer>
                <FadeSlideUpOnActiveContainer
                  active={animationUnlocked}
                  duration={5}
                  style={{ marginTop: "30px", alignSelf: "flex-end" }}
                >
                  <AniLink
                    cover
                    bg={`#f38f1d`}
                    duration={1}
                    direction="top"
                    to={services[serviceIndex].data.active_link ?
                      `/${locale}/services/${services[serviceIndex].uid}`
                      : goTo
                    }
                  >
                    <Button>
                      {services[serviceIndex].data.link_label.text}
                    </Button>
                  </AniLink>
                </FadeSlideUpOnActiveContainer>
              </>
            )}
          </ServiceInfo>
        </ServicesContainer>
      </Wrapper>
    </Container>
  );
};

export default handleViewport(Services);
