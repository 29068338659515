import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 50px;
`;
const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 75px;
`;
const ServiceImages = styled.div`
  height: 420px;
  width: 420px;
  min-width: 420px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  transform: rotate(-45deg);
`;
const ServiceLosange = styled.div`
  flex: 0 0 50%;
  overflow: hidden;
`;

const ServiceBorder = styled.div`
  height: 400px;
  width: 400px;
  position: absolute;
  border-top: 2px solid black;
  border-left: 2px solid black;
  top: 210px;
  left: 210px;
`;

const ServiceImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  transform: rotate(90deg);

  cursor: pointer;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);

  /* Sadly, transition causes an ugly border width bug on Chrome */
  transition: 0.5s filter ease, 0.5s -webkit-filter ease;
  transition: 0.5s box-shadow ease, 0.5s -webkit-filter ease;

  &:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  &.active {
    box-shadow: -4px 4px 7px 7px #f38f1d;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
`;

const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PreviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 132px;
`;

const ServiceLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 176px;
  border: 1px solid #f38f1d;

  span {
    letter-spacing: 1.5px;
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: black;
    font-style: normal;

    &:hover {
      text-decoration: none;
    }
  }
`;

const styles = {
  Container,
  Wrapper,
  ServicesContainer,
  ServiceLosange,
  PreviewsContainer,
  ServiceImages,
  ServiceImage,
  ServiceInfo,
  ServiceBorder,
  ServiceLink
};

export default styles;
