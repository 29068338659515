import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  margin-top: 155px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Cblog = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
`;

const styles = {
  Post,
  Container,
  Wrapper,
  Cblog
};

export default styles;
