import React, { FunctionComponent, ButtonHTMLAttributes } from "react";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./styles.css";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  transparent?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  to,
  children,
  transparent,
  ...rest
}) => {
  if (to) {
    return (
      <AniLink
        cover
        to={to}
        bg="#f38f1d"
        duration={1}
        direction="top"
        className={`btn${transparent ? " transparent" : ""}`}
      >
        {children}
      </AniLink>
    );
  }

  return (
    <button className={`btn${transparent ? " transparent" : ""}`} {...rest}>
      {children}
    </button>
  );
};

export default Button;
